@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap&subset=latin-ext,vietnamese');

body {
	min-height: 100vh;
	height: 100% !important;
	font-family: 'Nunito Sans', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
	max-width: 100vw;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	.btn {
		padding: 5px 0 5px 5px;
		margin-left: -5px;
		box-shadow: none !important;
		border: none;
		background: none;

		.chevron {
			height: 4px;
			transform: rotate(-90deg) translateX(1px);
			margin-left: 10px;
		}

		&.btn-border {
			margin-left: 0;
			padding: 15px;
			border-width: 1px;
			border-style: solid;
			border-radius: 2px;
		}

		&.btn-white {
			.small-letters(#fff);
			border-color: #fff;
		}

		&.btn-black {
			.small-letters(#000);
			border-color: #000;
		}

		&.btn-filled {
			.small-letters(#fff);
			background: @color1;
			padding: 15px 20px;
			border: 1px solid @color1;
			border-radius: 2px;

			&:hover {
				background: darken(@color1, 10%);
				border-color: darken(@color1, 10%);
			}
		}
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}
}

@color1: #009cff;

// Mixins 

.small-letters(@color) {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.7px;
	color: @color;
}

// Others 

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.alert-ue {
	border: none;
	border-radius: 0;
	text-align: center;
	margin: 0;
	background: #f7f6f6;
	padding: 10px;

	img {
		height: 33px;
	}

	.close {
		height: 100%;
		outline: none;
	}
}

.section-sticky {
	position: sticky;
	top: 0;
}

.select2-dropdown {
	width: 200px !important;
	background: #222;
	border: none;
	border-radius: 0;

	.select2-search--dropdown {
		.select2-search__field {
			background: #000;
			border-radius: 0 !important;
			border: 1px solid rgba(255, 255, 255, 0.25) !important;
			height: 50px;
			padding-left: 12px;
			color: #fff;
			font-weight: 700;
			font-size: 16px;

			&:focus-visible {
				outline: none;
			}
		}
	}

	.select2-results {
		.select2-results__options {
			padding: 0 4px 4px 4px;

			.select2-results__option {
				color: #fff;
				font-weight: 700;
				font-size: 14px;
				background: #333;

				&.select2-results__option--highlighted {
					background: #444;
				}

				&.select2-results__option--selected {
					background: #666;
				}

				& + .select2-results__option {
					margin-top: 4px;
				}
			}
		}
	}
}

.body-wrapper {
	// overflow: hidden;
}

nav.section-header {
	padding: 30px 0 0 0;
	border-bottom: 1px solid #f7f6f6;
	position: relative;
	background: #fff;
	z-index: 2222;

	.header-top-wrapper {
		background: #fff;
		padding: 20px 0;
	}

	.header-top {
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			display: flex;
		}

		img {
			margin: 0 40px;
			height: 21px;
		}

		.top-line {
			background: #000;
			height: 1px;
			width: 100%;
		}
	}

	.header-flexbox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.flexbox-logo {
			a {
				display: flex;
				position: relative;

				img {
					height: 13px;
				}
			}
		}

		.flexbox-logo-mobile {
			display: none;

			img {
				height: 18px;
			}
		}

		.flexbox-expand {
			width: 100%;
			display: none;
		}

		.flexbox-language {
			width: 20%;

			.dropdown {
				.dropdown-toggle {
					.small-letters(#000);
					display: flex;
					align-items: center;
					padding: 0;
					background: none;
					border: none;
					outline: none;
					cursor: pointer;

					.flag {
						height: 15px;
						margin: 0 10px;
					}

					.chevron {
						height: 4px;
						transition: all 0.3s;

						&.active {
							transform: rotate(-180deg);
						}
					}

					&:after {
						content: none;
					}
				}

				.dropdown-menu {
					background: rgba(255, 255, 255, 0.6);
					top: 23px !important;
					padding: 0;
					border: none;
					border-radius: 0;

					.dropdown-item {
						.small-letters(#000);
						display: flex;
						align-items: center;
						padding: 5px 7px;
						transition: all 0.3s;
						outline: none;

						.flag {
							height: 15px;
							margin-right: 10px;
						}

						&:active {
							background: #0c1f4c;
							color: #fff;
						}
					}
				}
			}
		}

		.flexbox-nav-wrapper {
			width: 60%;

			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before, &:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					.button-bar {
						display: block;
						position: absolute;
						width: 20px;
						height: 2px;
						background: #000;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				.flexbox-nav {
					display: flex;
					justify-content: center;
					align-items: center;

					li {
						a, button {
							.small-letters(#000);
							display: block;
							padding: 25px;
							white-space: nowrap;
							position: relative;
							background: none;
							border: none;
							cursor: pointer;
							outline: none;

							&:after {
								content: '';
								position: absolute;
								bottom: 27px;
								left: 25px;
								width: calc(~'100% - 50px');
								height: 1px;
								display: block;
								background: #000;
								opacity: 0;
								transition: all 0.4s;
							}

							&.active {
								&:after {
									opacity: 1;
								}
							}
						}

						&.display-mobile {
							display: none;
						}
					}
				}

				.flexbox-nav-language {
					display: none;
					border-top: 1px solid #f7f6f6;
					padding-top: 30px;
					margin-top: 30px;
					list-style: none;

					li {
						a {
							.small-letters(#000);
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 10px;
							transition: all 0.3s;
							outline: none;

							.flag {
								height: 15px;
								margin-right: 10px;
							}

							&:active {
								background: #0c1f4c;
								color: #fff;
							}
						}
					}
				}
			}
		}

		.flexbox-shopping-cart {
			width: 20%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			a, button {
				position: relative;
				outline: none;
				border: none;
				padding: 0;
				background: none;
				cursor: pointer;

				.quantity {
					position: absolute;
					top: 61%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 8px;
					font-weight: 700;
					color: #000;
				}
			}

			.cart-image {
				width: 22px;
				transform: translateY(-1px);
			}

			.button-buy {
				.small-letters(#000);
				display: flex;
				align-items: center;
				border: 1px solid #000;
				border-radius: 2px;
				padding: 3px 15px;
				margin-left: 50px;

				.chevron {
					height: 4px;
					transform: rotate(-90deg);
					margin-left: 10px;
				}
			}
		}

		.bag-dropdown {
			position: absolute;
			max-width: 294px;
			right: 0;
			bottom: 0;
			transform: translateY(100%);
			border: 1px solid #cccccc;
			background: #fff;
			padding: 40px 20px;
			z-index: -1;
			opacity: 0;
			visibility: hidden;
			transition: all 0.4s;

			.dropdown-chevron {
				border: 1px solid #cccccc;
				background: #fff;
				top: 0;
				right: 7px;
				height: 7px;
				width: 7px;
				position: absolute;
				transform: translateY(-50%) rotate(-45deg);

				&:after {
					content: '';
					background: #fff;
					width: 16px;
					height: 10px;
					top: 0.5px;
					right: -1px;
					position: absolute;
					transform: rotate(45deg);
				}

				&.center-position {
					right: 130px;
				}
			}

			.dropdown-inner {
				p {
					font-size: 17px;
					font-weight: 300;
				}

				.inner-service {
					padding: 20px 0;
					border-bottom: 1px solid #dadada;
					display: flex;
					align-items: center;
					position: relative;
					padding-right: 25px;

					.service-image {
						width: 50%;
						text-align: center;

						img {
							max-width: 100%;
							max-height: 40px;
							height: auto;
							width: auto;
						}
					}

					.service-name {
						width: 50%;
						min-width: 113px;
						padding-left: 15px;
						font-size: 13px;
						font-weight: 300;
					}

					.service-delete {
						position: absolute;
						right: 0;
						height: 10px;
						width: 10px;
						top: 50%;
						transform: translateY(-50%);
						border: none;
						background: none;
						outline: none;
						padding: 0;
						cursor: pointer;

						span {
							position: absolute;
							display: block;
							left: 0;
							top: 0;
							width: 10px;
							height: 1px;
							background: #000;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}

					&:last-child {
						padding-bottom: 0;
						border: none;

						.service-delete {
							transform: translateY(calc(~'-50% + 10px'));
						}
					}
				}
			}

			.btn {
				margin-top: 30px;
			}

			&.active {
				z-index: 111;
				opacity: 1;
				visibility: visible;
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			justify-content: center;
			outline: none;
			display: none;
			cursor: pointer;
			
			.button-bar {
				height: 2px;
				width: 17px;
				background: #000;
				transition: all 0.4s;

				&:not(:first-child) {
					margin-top: 6px;
				}

				&:nth-child(3) {
					width: 8px;
				}
			}

			&:hover, &:focus {
				.button-bar {
					width: 17px !important;
				}
			}
		}
	}

	.product-dropdown {
		position: absolute;
		width: 100%;
		bottom: 0;
		transform: translateY(100%);
		background: #212122;
		padding: 25px 0;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: all 0.4s;

		.dropdown-inner {
			display: flex;
			justify-content: center;

			.inner-service {
				display: flex;
				align-items: center;

				.service-name {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					span {
						.small-letters(#fff);
					}

					.logo {
						margin-top: 10px;
						height: 10px;
					}
				}

				.service-flexbox {
					margin-left: 40px;
					display: flex;
					align-items: center;
					justify-content: center;

					.flexbox-service {
						img {
							height: 40px;
						}

						& + .flexbox-service {
							margin-left: 20px;
						}
					}
				}

				& + .inner-service {
					margin-left: 60px;
				}
			}
		}

		&.active {
			z-index: 111;
			opacity: 1;
			visibility: visible;
		}
	}

	&.product {
		padding: 0;
		background: #000;
		border: none;

		.flexbox-nav-wrapper {
			width: auto;
			margin-right: 70px;

			.flexbox-nav li a {
				color: #fff !important;
			}
		}

		.flexbox-shopping-cart {
			width: auto;

			.button-buy {
				color: #fff;
				border-color: #fff;
			}

			.quantity {
				color: #fff !important;
			}
		}

		.flexbox-inner {
			display: flex;
			align-items: center;
		}
	}

	&.no-border {
		border-bottom: none;
	}
}

section.section-welcome {
	position: relative;
	overflow: hidden;

	.welcome-background {
		position: relative;
		width: 110%;
	}

	.welcome-background-mobile {
		display: none;
	}

	.welcome-inner {
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 50px 0;

		.container {
			height: 100%;
		}

		.inner-wrapper {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.wrapper-service {
				.logo {
					height: 15px;
				}

				h1 {
					margin-top: 5px;
					font-size: 50px;
					font-weight: 200;
					color: #fff;
				}

				.buttons-wrapper {
					margin-top: 32px;
					display: flex;
					justify-content: center;
					align-items: center;

					.btn {
						&:not(:last-child) {
							margin-right: 50px;
						}
					}
				}
			}
		}
	}
}

section.section-offer {
	background: #f7f6f6;

	.offer-inner {
		display: flex;

		.inner-service {
			width: 50%;
			padding: 50px 0 0 0;
			text-align: center;

			.logo {
				height: 12px;
			}

			h2 {
				margin-top: 5px;
				font-size: 40px;
				font-weight: 200;
				padding: 0 50px;
			}

			p {
				margin: 4px auto 0 auto;
				font-size: 16px;
				max-width: 380px;
				font-weight: 200;
			}

			.device {
				margin-top: 65px;
				height: 350px;
				overflow: hidden;
			}

			.buttons-wrapper {
				margin-top: 32px;
				display: flex;
				justify-content: center;
				align-items: center;

				.btn {
					&:not(:last-child) {
						margin-right: 50px;
					}
				}
			}

			&:first-child {
				border-right: 1px solid #fff;

				.device {
					img {
						margin-top: 25px;
						width: 95%;
					}
				}
			}

			&:last-child {
				.device {
					img {
						transform: translateY(-40px);
						height: 380px;
					}
				}
			}
		}
	}
}

section.section-product {
	padding: 50px 0;
	overflow: hidden;

	.product-flexbox {
		position: relative;
		display: flex;
		align-items: center;

		.flexbox-service {
			&:nth-child(1) {
				width: 50%;
				position: relative;

				.service-image {
					position: relative;
					transform: translateX(-5%);

					img {
						width: 120%;

						&:not(:first-child) {
							position: absolute;
							top: 0;
							left: 0;
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;
				padding: 120px 0;
				color: #000;
				width: 50%;

				h2 {
					font-size: 50px;
					font-weight: 200;
				}

				h3 {
					margin-top: 30px;
					font-size: 28px;
					font-weight: 200;
				}

				p {
					margin-top: 30px;
					font-size: 16px;
					font-weight: 200;
				}

				.buttons-wrapper {
					margin-top: 32px;
					display: flex;
					align-items: center;

					.btn {
						&:not(:last-child) {
							margin-right: 50px;
						}
					}
				}
			}
		}
	}
}

section.section-swiss {
	padding: 80px 0;
	overflow: hidden;
	position: relative;
	background: #f7f6f6;

	.swiss-made {
		height: 40px;
		position: absolute;
		right: 100px;
		bottom: 100px;
		z-index: 111;
	}

	.swiss-flexbox {
		display: flex;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				padding: 70px 0;
				padding-right: 50px;
				color: #000;

				h2 {
					font-size: 50px;
					font-weight: 200;
				}

				h3 {
					margin-top: 30px;
					font-size: 28px;
					font-weight: 200;
				}

				p {
					margin-top: 30px;
					font-size: 16px;
					font-weight: 200;
				}

				.buttons-wrapper {
					margin-top: 32px;
					display: flex;
					align-items: center;

					.btn {
						&:not(:last-child) {
							margin-right: 50px;
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					left: 50px;
					top: 10%;
					height: 80%;
				}
			}
		}
	}
}

.section-features-wrapper {
	background: #f7f6f6;
	position: relative;

	&.active {
		height: 150vh;
	}
}

section.section-features {
	padding: 100px 0 0 0;
	overflow: hidden;
	min-height: 100vh;

	.section-heading {
		text-align: center;
		position: relative;
		z-index: 111;

		h2 {
			font-size: 50px;
			font-weight: 200;
		}
	}

	.nav-tabs {
		padding-top: 40px;
		margin-top: -20px; // Wysokość padding top .nav-item
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
		border: none;
		position: relative;
		z-index: 111;

		.nav-item {
			.small-letters(#000);
			padding: 20px 20px 0 20px;
			margin: 0;
			border: none;
			white-space: nowrap;
			position: relative;
			background: none;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 20px;
				width: calc(~'100% - 40px');
				height: 1px;
				display: block;
				background: transparent;
				transition: all 0.4s;
			}

			&.active {
				&:after {
					background: #000;
				}
			}
		}
	}

	.tab-content {
		.tab-pane {
			.feature-flexbox {
				display: flex;

				.flexbox-service {
					width: 50%;

					&:nth-child(1) {
						position: relative;

						.service-image {
							position: absolute;
						}
					}

					&:nth-child(2) {
						position: relative;
						padding-left: 35px;
						color: #000;
						min-height: 650px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;

						h3 {
							font-size: 34px;
							font-weight: 200;
						}

						p {
							margin-top: 30px;
							font-size: 16px;
							font-weight: 200;
						}

						.lights-wrapper {
							margin-top: 30px;
							display: grid;
							grid-template-columns: 1fr 1fr 1fr 1fr;
							grid-gap: 10px;

							.light-service {
								width: 40px;
								height: 40px;
								border-radius: 50%;
								padding: 0;
								border: none;
								outline: none;
								cursor: pointer;
								transition: all 0.4s;

								&.active {
									box-shadow: 0px 0px 10px 1px #94bfb6;
								}
							}
						}

						.buttons-wrapper {
							margin-top: 32px;
							display: flex;
							align-items: center;

							.btn {
								&:not(:last-child) {
									margin-right: 50px;
								}
							}
						}
					}
				}
			}

			&.pane-silence {
				.service-image {
					width: calc(~'100% + 100px');
					right: 35px;
					top: 50%;
					transform: translateY(-50%);
				}	
			}

			&.pane-precision {
				.service-image {
					height: calc(~'100% - 100px');
					right: 35px;
					top: 50px;
				}	
			}

			&.pane-safety {
				.service-image-wrapper {
					height: calc(~'100% - 50px');
					right: 35px;
					top: 0;
					position: absolute;

					img {
						top: 0;
						right: 0;
						height: 100%;
						position: absolute;
						opacity: 0;
						transition: opacity 1s;

						&.show {
							opacity: 1;
						}
					}
				}
			}

			&.pane-craftmenship {
				.service-image {
					height: calc(~'130%');
					right: 0;
					top: 50px;
				}
			}

			&.pane-setup {
				.service-image {
					height: calc(~'100% - 100px');
					right: 35px;
					top: 50px;
				}
			}

			&.pane-light {
				.service-image-wrapper {
					height: calc(~'150%');
					right: 35px;
					top: -100px;
					position: absolute;

					img {
						top: 0;
						right: 0;
						height: 100%;
						position: absolute;
						opacity: 0;
						transition: opacity 0.4s;

						&.active {
							opacity: 1;
						}
					}
				}	
			}

			&.pane-mechanism {
				.service-image {
					height: calc(~'135%');
					right: -50px;
					top: 50px;
				}	
			}

			&.pane-design {
				.service-image {
					height: calc(~'100% - 100px');
					right: 35px;
					top: 50px;
				}	
			}

			&.pane-balance-setup {
				.service-image {
					width: auto;
					max-width: 57vw;
					right: -7vw;
					bottom: 0;
				}
			}

			&.pane-modularity {
				.service-image {
					right: -70px;
					bottom: 0;
					height: calc(100% + 150px);
				}
			}

			&.pane-impact-design {
				.service-image {
					right: 0;
					top: 12%;
					height: 80%;
					transform: translateX(20%);
				}
			}

			&.pane-battery {
				.battery-icon {
					position: absolute;
					padding: 20px;
					background: rgba(247, 246, 246, 0.5);
					left: 3%;
					bottom: calc(14%);
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					text-align: center;

					span {
						font-size: 40px;
						color: #111113;
						line-height: 40px;
						font-weight: 200;
					}

					img {
						margin-top: 15px;
						width: 40px;
					}
				}

				.service-image {
					right: 0;
					top: 20%;
					height: 60%;
					transform: translateX(20%);
				}
			}

			&.pane-impact-setup {
				.service-image {
					right: 0;
					top: 10%;
					height: 85%;
					transform: translateX(27%);
				}
			}

			&.pane-quality {
				.service-image {
					right: 0;
					top: 0;
					height: 125%;
					transform: translateX(7%);
				}
			}
		}
	}

	&.dark {
		background: #cbcbcb;
	}
}

section.section-about {
	padding: 70px 0;
	overflow: hidden;
	position: relative;

	.section-heading {
		h2 {
			font-size: 50px;
			font-weight: 200;
			color: #fff;
			text-align: center;
		}
	}

	.swiper {
		width: 100%;

		.swiper-slide {
			height: auto !important;
		}

		.swiper-navigation-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 40px;
		}

		.swiper-pagination {
			position: relative;
			display: flex;
			margin: 0 30px;
			width: auto;
			bottom: auto;

			.swiper-pagination-bullet {
				height: 7px;
				width: 7px;
				border-radius: 0;
				background: rgba(255, 255, 255, 0.5);
				margin: 0;

				&.swiper-pagination-bullet-active {
					background: rgba(255, 255, 255, 1);
				}

				& + .swiper-pagination-bullet {
					margin-left: 15px;
				}
			}
		}

		.swiper-button-prev, .swiper-button-next {
			position: relative;
			right: auto;
			left: auto;
			top: auto;
			margin: 0;
			height: 20px;
			width: 20px;
			background: url(../img/icons/right-chevron-white.svg) center;
			background-size: 100%;
			background-repeat: no-repeat;

			&:after {
				content: none;
			}
		}

		.swiper-button-prev {
			transform: rotate(180deg);
		}
	}

	.about-item {
		margin-top: 70px;

		h3 {
			color: #fff;
			font-size: 28px;
			font-weight: 200;
		}

		p {
			color: #fff;
			margin-top: 30px;
			font-size: 16px;
			font-weight: 200;
		}
	}

	.buttons-wrapper {
		margin-top: 70px;
		text-align: center;
	}
}

section.product-section-canvas {
	height: 300vh;
	position: relative;
	background: #fff;

	canvas {
		z-index: 1111;
		position: sticky;
		top: 10vh;
		left: 0;
		width: 100%;
		height: 80vh;
		object-fit: contain;
		object-position: center;
	}

	img {
		width: 100%;
	}
}

section.product-section-welcome-image {
	background: #fff;
	overflow: hidden;

	.welcome-image-inner {
		min-height: calc(100vh - 135px);
		display: flex;
		align-items: center;

		img {
			width: 100%;
		}
	}
}

.product-section-welcome-wrapper {
	background: #f7f6f6;
	position: relative;

	&.active {
		height: 200vh;
	}
}

section.product-section-welcome {
	padding: 70px 0;
	overflow: hidden;

	.welcome-background {
		position: absolute;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 1920px;

		img {
			object-fit: cover;
			object-position: center;
			height: 100%;
			width: 100%;
		}
	}

	.welcome-flexbox {
		display: flex;
		position: relative;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				padding: 100px 0;
				padding-right: 50px;
				color: #000;

				.genesis-logo {
					height: 20px;
				}

				h1 {
					margin-top: 10px;
					font-size: 50px;
					font-weight: 200;
					margin-left: -5px;
					line-height: 80px;
				}

				p {
					margin-top: 30px;
					font-size: 16px;
					font-weight: 200;
				}

				.buttons-wrapper {
					margin-top: 32px;
					display: flex;
					align-items: center;

					.btn {
						&:not(:last-child) {
							margin-right: 50px;
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					left: 50px;
					top: 0;
					height: 100%;
				}
			}
		}
	}

	&.dark {
		background: #272727;

		.welcome-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					color: #fff;
				}
			}
		}
	}
}

section.product-section-hero-image {
	height: 150vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	.image-service {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: -1;
	}

	.image-inner {
		position: relative;
		z-index: 222;
		padding: 0 20px;
		max-width: 700px;

		h2 {
			font-size: 50px;
			font-weight: 200;
			color: #fff;
		}
	}
}

section.product-section-gallery {
	padding: 80px 0 60px 0;
	background: #fff;

	.owl-carousel {
		img {
			width: 100%;
			opacity: 0;
		}

		.item {
			background-size: auto 100%;
		}

		.owl-stage-outer {
			&:before {
				content: '';
				display: block;
				bottom: -1px;
				left: 0;
				height: 2px;
				width: 100%;
				background: #fff;
				position: absolute;
				z-index: 111;
			}
		}

		.owl-controls-wrapper {
			display: flex;
			justify-content: center;
			position: relative;
		}

		.owl-controls {
			margin-top: 30px;
			display: inline-block;
			position: relative;

			.owl-dots {
				display: flex;
				justify-content: center;
				position: relative;

				.owl-dot {
					min-height: auto;
					min-width: auto;
					display: flex;
					justify-content: center;
					align-items: center;

					span {
						background-color: #dedddd;
						min-width: 7px;
						max-width: 7px;
						height: 7px;
						border-radius: 0;
						margin: 0;
						transition: all 0.3s;
					}

					&.active {
						span {
							background-color: #000;
						}
					}

					& + .owl-dot {
						margin-left: 15px;
					}
				}
			}

			.owl-nav {
				position: absolute;
				width: 100%;
				height: 100%;
				margin-top: 0;
				top: 0;
				left: 0;

				.owl-prev, .owl-next {
					background: url(../img/icons/right-chevron.svg) center;
					background-size: 100%;
					background-repeat: no-repeat;
					padding: 0;
					margin: 0;
					height: 20px;
					width: 20px;
					top: 50%;
					position: absolute;
					font-size: 0;
				}

				.owl-prev {
					transform: translateY(-50%) rotate(180deg);
					left: -55px;
				}

				.owl-next {
					transform: translateY(-50%);
					right: -55px;
				}
			}
		}
	}

	&.st1 {
		padding: 0;
		padding-bottom: 60px;

		.owl-carousel {
			.owl-controls-wrapper {
				margin-top: -40px;
			}
		}
	}
}

.product-section-specs-wrapper {
	background: #fff;
	position: relative;

	&.active {
		height: 200vh;
	}
}

section.product-section-specs {
	padding: 50px 0;
	overflow: hidden;

	&.padding-100 {
		padding: 100px 0;
	}

	.section-heading {
		text-align: center;
		margin-bottom: 100px;

		h2 {
			font-size: 50px;
			font-weight: 200;
			color: #212529;
		}
	}

	.about-flexbox {
		display: flex;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				padding: 100px 0;
				padding-right: 50px;
				position: relative;
				z-index: 111;
				color: #000;

				.service-heading {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					p {
						font-size: 16px;
						font-weight: 700;
					}

					.value {
						font-size: 50px;
						font-weight: 200;
						line-height: 55px;
					}

					& + ul {
						margin-top: 40px;
					}
				}

				ul {
					li {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						p {
							font-size: 16px;
							font-weight: 200;

							&:nth-child(2) {
								font-weight: 700;
							}
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					left: 50px;
					top: 0;
					height: 100%;

					&.st1 {
						left: -10%;
						top: 10%;
						height: 115%;
					}
				}
			}
		}
	}
}

.product-section-versions-wrapper {
	background: #fff;
	position: relative;

	&.active {
		height: 200vh;
	}
}

section.product-section-versions {
	padding: 80px 0;

	&.section-sticky {
		top: -80px;
	}

	h2 {
		font-size: 50px;
		font-weight: 200;
		text-align: center;
	}

	.versions-options {
		margin-top: 50px;
		display: none;
		align-items: center;
		justify-content: center;

		a, button {
			padding: 0;
			background: none;
			border: none;
			outline: none;
			cursor: pointer;

			img {
				height: 19px;
				opacity: 0.5;
				transition: opacity 0.4s;
			}

			& + a {
				margin-left: 20px;
			}

			& + button {
				margin-left: 20px;
			}

			&.active {
				img {
					opacity: 1;
				}
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}
	}

	.versions-flexbox {
		margin-top: 80px;
		display: flex;

		&.flexbox-3 {
			.flexbox-service {
				width: 100% / 3;

				.device {
					max-height: 150px;
					transform: none;
				}

				.logo {
					margin-top: 20px;
				}
			}
		}

		.flexbox-service {
			width: 25%;

			.device {
				// max-height: 100px;
				max-height: 60px;
				max-width: 100%;
				width: auto;
				height: auto;
				margin-bottom: 15px;
				transform: translateX(-5%);
			}

			.logo {
				margin-top: 8px;
				display: flex;
				align-items: center;

				img {
					height: 14px;
				}

				p {
					margin-left: 26px;
					color: #000;
					font-size: 20px;
					line-height: 14px;
					font-weight: 400;
				}
			}

			.price {
				width: 100%;
				margin-top: 35px;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				p {
					font-size: 16px;
					font-weight: 200;
				}

				.value {
					font-size: 40px;
					font-weight: 200;
					line-height: 40px;
				}

				.btn {
					display: none;
				}
			}

			ul {
				margin-top: 30px;

				li {
					p {
						font-size: 14px;
						font-weight: 200;

						b {
							font-weight: 700;
						}
					}

					& + li {
						margin-top: 20px;
					}
				}
			}

			.btn {
				margin-top: 40px;
			}
		}
	}

	.versions-configurator {
		margin-top: 100px;
		display: flex;
		justify-content: space-between;

		.configurator-gallery {
			width: 45%;
			position: relative;

			.gallery-item {
				opacity: 0;
				transition: all 0.5s;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				img {
					height: 100%;
				}

				&.active {
					opacity: 1;
				}
			}
		}

		.configurator-body {
			width: 50%;
			position: relative;

			h3 {
				font-size: 34px;
				font-weight: 200;

				&:not(:first-child) {
					margin-top: 70px;
				}
			}

			.body-options {
				margin-top: 30px;
				display: flex;
				align-items: center;

				.option-service {
					display: flex;
					align-items: center;
					padding: 0;
					border: none;
					outline: none;
					background: none;
					text-align: left;
					cursor: pointer;

					.service-image {
						height: 60px;
						width: 60px;
						border-radius: 50%;
						overflow: hidden;
						transition: all 0.5s;
						border: 3px solid transparent;

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
						}
					}

					.service-name {
						font-size: 18px;
						font-weight: 200;
						margin-left: 15px;
						width: 110px;
					}

					&.active {
						.service-image {
							border-color: @color1;
						}
					}

					& + .option-service {
						margin-left: 35px;
					}
				}
			}

			.body-price {
				width: 100%;
				margin-top: 70px;
				display: flex;
				align-items: flex-end;

				p {
					font-size: 16px;
					font-weight: 700;
				}

				.value {
					font-size: 40px;
					font-weight: 200;
					line-height: 40px;
				}

				.price-input {
					display: flex;
					border: 1px solid #d4d4d4;
					border-radius: 2px;
					height: 50px;
					padding: 0 10px;
					margin-left: 50px;

					.input-number {
						width: 50px;
						padding: 0 6px;
						text-align: center;
						outline: none;
						background: none;
					}

					.input-number,
					.input-number-decrement,
					.input-number-increment {
						color: #000;
						height: 50px;
						user-select: none;
						border: none;
						font-size: 16px;
						font-weight: 700;
					}

					.input-number-decrement,
					.input-number-increment {
						width: 20px;
						background: none;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: background 0.4s;
						cursor: pointer;
						border: none;
						outline: none;

						&:active {
							background: none;
						}
					}

					input[type=number]::-webkit-inner-spin-button, 
					input[type=number]::-webkit-outer-spin-button { 
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						margin: 0; 
					}

					input[type=number] {
						-moz-appearance:textfield;
					}
				}

				.btn {
					margin-left: 15px;
				}
			}
		}
	}
}

section.about-us-section-content {
	.content-heading {
		position: relative;

		.heading-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.heading-inner {
			max-width: 1000px;
			margin: 0 auto;
			padding: 120px 0;
			position: relative;

			p {
				font-size: 28px;
				font-weight: 200;
				color: #fff;
			}
		}
	}

	.about-slogan {
		overflow: hidden;

		.slogan-inner {
			position: relative;

			.inner-image {
				position: absolute;
				right: 40%;
				height: 100%;
			}

			.inner-description {
				position: relative;
				padding: 200px 0 200px 50%;

				h3 {
					font-size: 28px;
					font-weight: 200;
					color: #000;
				}

				p {
					.small-letters(#000);
					margin-top: 50px;
				}
			}
		}

		.slogan-image-mobile {
			display: none;
		}
	}

	.about-draft {
		background-color: #101010;
		padding: 50px 0 50px 0;

		img {
			width: 100%;
		}
	}
}

section.winder-section-content {
	padding-top: 100px;
	margin-bottom: 100px;
	min-height: 870px;
	overflow: hidden;

	.content-flexbox {
		display: flex;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				position: relative;

				img {
					position: absolute;
					right: -100px;
					top: 270px;
					height: 500px;
				}
			}

			&:nth-child(2) {
				position: relative;
				padding-left: 50px;
				color: #000;

				h1 {
					font-size: 50px;
					font-weight: 200;
				}

				h2 {
					margin-top: 30px;
					font-size: 28px;
					font-weight: 200;
				}

				.description {
					margin-top: 40px;

					p {
						font-size: 16px;
						font-weight: 200;

						b {
							font-weight: 600;
						}
					}
				}

				.content-search-engine {
					margin-top: 40px;
					margin-bottom: 30px;

					p {
						font-size: 16px;
						font-weight: 200;
					}

					input {
						margin-top: 5px;
						height: 50px;
						width: 100%;
						max-width: 400px;
						border: 1px solid rgba(0, 0, 0, 0.25);
						font-size: 16px;
						font-weight: 700;
						padding: 10px 15px;
						transition: all 0.4s;

						&:focus {
							outline: none;
							border: 1px solid rgba(0, 0, 0, 1);
						}
					}
				}

				.service-scroll {
					max-height: 750px;
					position: relative;
					border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
				}

				ul {
					padding-bottom: 20px;

					li {
						h3 {
							margin: 30px 0;
							font-size: 50px;
							font-weight: 200;
						}

						.name {
							font-size: 16px;
							font-weight: 200;
						}

						.feature {
							display: flex;
							justify-content: space-between;

							p {
								font-size: 14px;
								font-weight: 200;

								&:nth-child(2) {
									font-weight: 700;
								}
							}
						}

						&:first-child {
							h3 {
								// Do odkomentowania jeśli wrapper ze scrollem będzie aktywny
								// margin-top: 0;
							}
						}

						&:not(:last-child) {
							margin-bottom: 15px;
							padding-bottom: 15px;
							border-bottom: 0.5px solid #000;
						}
					}
				}
			}
		}
	}
}

section.downloads-section-content {
	padding-top: 100px;
	margin-bottom: 100px;
	overflow: hidden;

	.content-flexbox {
		display: flex;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				padding-right: 50px;
				color: #000;

				h1 {
					font-size: 50px;
					font-weight: 200;
				}

				.service-image-mobile {
					display: none;
				}

				.service-download {
					.download-box {
						margin-top: 50px;
						display: flex;
						align-items: center;
						color: #000;

						.box-image {
							min-width: 30%;
							max-width: 30%;
							margin-right: 30px;

							img {
								width: 100%;
							}
						}

						.box-description {
							.weight {
								.small-letters(#000);
								margin-top: 0;
							}

							h2 {
								margin-top: 5px;
								font-size: 28px;
								font-weight: 200;
							}

							p {
								margin-top: 20px;
								font-size: 16px;
								font-weight: 200;
							}

							.btn {
								margin-top: 20px;
							}
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					left: 50px;
					top: 0;
					height: 600px;
				}
			}
		}
	}
}

section.static-page-section-content {
	padding-top: 100px;
	margin-bottom: 100px;
	overflow: hidden;

	.content-flexbox {
		display: flex;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				padding-right: 50px;
				color: #000;

				h1 {
					font-size: 50px;
					font-weight: 200;
				}

				.service-inner {
					margin-top: 50px;

					h2 {
						font-size: 28px;
						font-weight: 200;

						a {
							color: inherit;
							font-weight: 700;
							transition: all 0.4s;

							&:hover {
								opacity: 0.5;
							}
						}
					}

					p, li {
						font-size: 16px;
						font-weight: 200;
					}

					ul {
						padding-left: 18px;
					}
				}

				.service-ue {
					margin-top: 50px;

					.ue-image {
						padding: 30px;
						background: #f9f9f9;

						img {
							width: 100%;
						}

						+ .ue-image {
							margin-top: 50px;
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					left: 50px;
					top: 0;
					height: 500px;
				}
			}
		}
	}
}

section.blog-section-content {
	padding-top: 100px;
	margin-bottom: 100px;
	overflow: hidden;

	.content-flexbox {
		display: flex;

		.flexbox-service {
			width: 50%;

			.newest-inner {
				margin-top: 30px;
				display: block;

				.inner-image {
					transition: all 0.4s;

					img {
						width: 100%;
					}
				}

				.inner-description {
					margin-top: 30px;
					color: #000;

					.date {
						.small-letters(#000);
					}

					h2 {
						font-size: 28px;
						font-weight: 200;
						margin: 7px 0;
					}

					p {
						font-size: 16px;
						font-weight: 200;
					}

					.btn {
						margin-top: 20px;
					}
				}

				&:hover {
					.inner-image {
						opacity: 0.7;
					}
				}
			}

			.news-wrapper {
				.news-service {
					margin-top: 30px;
					padding-top: 30px;
					border-top: 1px solid #f7f6f6;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.service-image {
						width: 47%;
						transition: all 0.4s;

						img {
							width: 100%;
						}
					}

					.service-description {
						width: 47%;
						color: #000;

						.date {
							.small-letters(#000);
						}

						h2 {
							font-size: 22px;
							font-weight: 200;
							margin: 4px 0;
						}

						p {
							font-size: 14px;
							font-weight: 200;
						}

						.btn {
							margin-top: 20px;
						}
					}

					&:hover {
						.service-image {
							opacity: 0.7;
						}
					}
				}

				&.see-also {
					.news-service {
						&:first-child {
							margin-top: 0;
							padding-top: 0;
							border: none;
						}
					}
				} 
			}

			.post-inner {
				margin-top: 30px;

				.inner-heading {
					.date {
						.small-letters(#000);
					}

					h1 {
						font-size: 28px;
						font-weight: 200;
						margin: 10px 0 30px 0;
					}

					p {
						font-size: 16px;
						font-weight: 200;
					}

					img {
						margin-top: 30px;
						width: 100%;
					}
				}

				.inner-description {
					margin-top: 30px;
					padding-bottom: 50px;
					margin-bottom: 80px;
					border-bottom: 1px solid #f7f6f6;

					h2 {
						font-size: 28px;
						font-weight: 200;

						a {
							color: inherit;
							font-weight: 700;
							transition: all 0.4s;

							&:hover {
								opacity: 0.5;
							}
						}
					}

					p, li {
						font-size: 16px;
						font-weight: 200;
					}

					ul {
						padding-left: 18px;
					}
				}
			}

			.buttons-wrapper {
				margin-top: 70px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn {
					&:first-child {
						display: flex;
						align-items: center;
						flex-direction: row-reverse;

						img {
							margin-left: 0;
							margin-right: 10px;
							transform: rotate(90deg) translateX(-1px);
						}
					}
				}
			}

			&:nth-child(1) {
				padding-right: 50px;
				color: #000;

				h1, .service-heading {
					font-size: 50px;
					font-weight: 200;
					display: flex;
					align-items: flex-end;

					a {
						color: inherit;
						margin-bottom: 8px;
						margin-right: 18px;
						opacity: 0.5;
						font-size: 0.5em;
						transition: all 0.4s;
						display: flex;
						align-items: center;

						img {
							height: 6px;
							transform: rotate(90deg) translateX(1px);
							margin-right: 5px;
						}

						&:hover {
							opacity: 1;
						}
					}
				}

				.service-heading-small {
					font-size: 28px;
					font-weight: 200;
					margin-bottom: 50px;
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					left: 50px;
					top: 90px;
					height: 500px;
				}
			}
		}
	}
}

section.contact-section-content {
	padding: 100px 0;
	overflow: hidden;
	background: #fff;

	.content-image {
		width: 100%;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	.content-heading {
		margin-top: 100px;

		.heading-inner {
			width: 50%;
			margin: 0 auto;
			color: #000;

			h1 {
				font-size: 50px;
				font-weight: 200;
				text-align: center;
			}

			h2 {
				margin-top: 30px;
				text-align: center;
				font-size: 28px;
				font-weight: 200;

				a {
					color: inherit;
					font-weight: 700;
					transition: all 0.4s;

					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
	}

	.content-form {
		.form-inner {
			width: 50%;
			margin: 50px auto 0 auto;
			color: #000;

			label {
				margin-top: 30px;
				font-size: 16px;
				font-weight: 200;
				margin-bottom: 0;
			}

			input, textarea {
				margin-top: 5px;
				height: 50px;
				width: 100%;
				border: 1px solid rgba(0, 0, 0, 0.25);
				font-size: 16px;
				font-weight: 700;
				padding: 10px 15px;
				transition: all 0.4s;

				&:focus {
					outline: none;
					border: 1px solid rgba(0, 0, 0, 1);
				}
			}

			.select-wrapper {
				margin-top: 5px;
				position: relative;
				border: 1px solid rgba(0, 0, 0, 0.25);

				select {
					cursor: pointer;
					padding: 5px 100px 5px 10px;
					border: none;
					background: none;
					height: 50px;
					-moz-appearance: none;
					-webkit-appearance: none;
					font-size: 16px;
					font-weight: 200;
					appearance: none;
					outline: none;
					display: block;
					color: #000;
					width: 100%;
				}

				&:after {
					cursor: pointer;
					content: '';
					height: 4px;
					width: 4px;
					background: url('../img/icons/down-chevron.svg') center;
					background-size: cover;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			textarea {
				height: 150px;
			}

			.btn {
				margin-top: 45px;
			}
		}
	}
}

section.checkout-section-content {
	background: #000;
	min-height: 610px;
	padding: 100px 0;
	overflow: hidden;

	.content-flexbox {
		display: flex;
		justify-content: center;

		.flexbox-service {
			width: 50%;

			&:nth-child(1) {
				padding-bottom: 2px;
				padding-right: 50px;
				color: #fff;
				position: relative;
				z-index: 111;

				h1 {
					font-size: 50px;
					font-weight: 200;
				}

				h2 {
					font-size: 28px;
					font-weight: 200;

					a {
						color: inherit;
						font-weight: 700;
						transition: all 0.4s;

						&:hover {
							opacity: 0.5;
						}
					}
				}

				.service-versions::-webkit-scrollbar {
					display: none;
				}

				.service-versions {
					margin-top: 35px;
					display: flex;
					align-items: center;
					overflow-y: scroll;
					-ms-overflow-style: none;
					scrollbar-width: none;

					.version-box {
						cursor: pointer;
						display: block;
						padding: 0;
						margin: 15px 15px 0 0;

						.box-inner {
							display: block;
							padding: 15px;
							border-radius: 2px;
							border: 1px solid rgba(255, 255, 255, 0.25);
							transition: all 0.4s;
							text-align: center;

							img {
								max-height: 45px;

								&.big {
									max-height: 60px;
								}
							}

							p {
								margin-top: 12px;
								font-size: 11px;
								font-weight: 300;
							}
						}

						input {
							visibility: hidden;
							position: absolute;
							width: auto !important;
						}
					}

					.version-box > input:checked + .box-inner {
							border: 1px solid rgba(255, 255, 255, 1);
					}
				}

				.quantity-wrapper-outer {
					margin-top: 100px;

					.quantity-heading {
						display: flex;
						align-items: center;
						justify-content: space-between;

						h2 {
							font-size: 28px;
							font-weight: 200;
						}

						.delete-button {
							background: none;
							outline: none;
							padding: 0;
							cursor: pointer;
							display: flex;
							align-items: center;
							border: none;

							.text {
								.small-letters(#fff);
							}

							.icon {
								margin-left: 10px;
								height: 10px;
								width: 10px;
								transform: translateY(3px);
								position: relative;

								span {
									position: absolute;
									display: block;
									left: 0;
									top: 0;
									width: 6px;
									height: 1px;
									background: #fff;

									&:nth-child(1) {
										transform: rotate(45deg);
									}

									&:nth-child(2) {
										transform: rotate(-45deg);
									}
								}
							}
						}
					}

					.quantity-version {
						margin-top: 40px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						h3 {
							font-size: 16px;
							font-weight: 200;
						}
					}

					.quantity-image {
						margin-top: 40px;
						max-width: 245px;
						max-height: 90px;
						height: auto;
						width: auto;
					}

					.quantity-wrapper {
						margin-top: 50px;
						padding: 15px 0;
						border-top: 1px solid rgba(255, 255, 255, 0.5);
						border-bottom: 1px solid rgba(255, 255, 255, 0.5);
						display: flex;
						justify-content: space-between;
						align-items: center;

						.wrapper-input {
							display: flex;

							.input-number {
								width: 50px;
								padding: 0 12px;
								text-align: center;
								outline: none;
								background: none;
							}

							.input-number,
							.input-number-decrement,
							.input-number-increment {
								color: #fff;
								height: 50px;
								user-select: none;
								border: none;
								font-size: 16px;
								font-weight: 700;
							}

							.input-number-decrement,
							.input-number-increment {
								width: 30px;
								background: none;
								text-align: center;
								display: flex;
								align-items: center;
								justify-content: center;
								transition: background 0.4s;
								cursor: pointer;
								border: none;
								outline: none;

								&:active {
									background: none;
								}
							}

							input[type=number]::-webkit-inner-spin-button, 
							input[type=number]::-webkit-outer-spin-button { 
								-moz-appearance: none;
								-webkit-appearance: none;
								appearance: none;
								margin: 0; 
							}

							input[type=number] {
								-moz-appearance:textfield;
							}
						}

						.wrapper-price {
							text-align: right;

							.price {
								font-size: 20px;
								font-weight: 700;
								line-height: 20px;
							}

							.tax {
								color: #fff;
								font-size: 11px;
								font-weight: 200;
							}
						}
					}
				}

				.service-total {
					margin-top: 50px;
					display: flex;
					justify-content: flex-end;

					.total-inner {
						display: inline-block;
					}

					.total-delivery {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							font-size: 18px;
							font-weight: 200;

							&:nth-child(2) {
								font-weight: 700;
								margin-left: 30px;
							}
						}
					}

					.total-price {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							font-size: 28px;
							font-weight: 200;

							&:nth-child(2) {
								font-weight: 700;
								margin-left: 30px;
							}
						}
					}
				}

				.buttons-wrapper {
					margin-top: 50px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					&.margin-top-xl {
						margin-top: 150px;
					}

					> * {
						&:not(:last-child) {
							margin-right: 50px;
						}
					}

					#paypal-button-container {
						position: absolute !important;
						opacity: 0 !important;
						height: 100% !important;
						width: 100% !important;
					}

					.paypal-buttons {
						height: 100% !important;
					}

					.buttons-container {
						position: absolute !important;
						height: 100% !important;
						width: 100% !important;
					}

					.paypal-button-container {
						height: 100% !important;
						width: 100% !important;
					}

					.paypal-button {
						height: 100% !important;
						width: 100% !important;
					}
				}

				.content-form {
					margin-top: 50px;

					&.express-checkout-wrapper {
						margin-top: -38px;
					}

					label {
						margin-top: 30px;
						font-size: 16px;
						font-weight: 200;
						margin-bottom: 0;
					}

					input, textarea {
						margin-top: 5px;
						height: 50px;
						width: 100%;
						border: 1px solid rgba(255, 255, 255, 0.25);
						font-size: 16px;
						font-weight: 700;
						color: #fff;
						padding: 10px 15px;
						background: none;
						transition: all 0.4s;

						&:focus {
							outline: none;
							border: 1px solid rgba(255, 255, 255, 1);
						}
					}

					textarea {
						height: 150px;
					}

					.select-wrapper {
						margin-top: 5px;
						position: relative;
						border: 1px solid rgba(255, 255, 255, 0.25);

						select {
							cursor: pointer;
							padding: 5px 100px 5px 15px;
							border: none;
							background: none;
							height: 50px;
							-moz-appearance: none;
							-webkit-appearance: none;
							font-size: 16px;
							font-weight: 700;
							color: #fff;
							appearance: none;
							outline: none;
							display: block;
							width: 100%;
						}

						select option {
							color: #000;
						}

						&:after {
							cursor: pointer;
							content: '';
							height: 4px;
							width: 4px;
							background: url('../img/icons/down-chevron-white.svg') center;
							background-size: cover;
							position: absolute;
							right: 20px;
							top: 50%;
							transform: translateY(-50%);
						}
					}

					.radio-wrapper {
						cursor: pointer;
						display: block;
						margin-top: 0;

						.radio-box {
							position: relative;
							display: flex;
							align-items: center;

							img {
								height: 24px;
							}

							&:before {
								content: '';
								min-width: 10px;
								max-width: 10px;
								height: 10px;
								display: block;
								background: url('../img/icons/radio.svg') center no-repeat;
								background-size: cover;
								transition: all 0.3s;
							}
						}

						input {
							visibility: hidden;
							position: absolute;
							width: auto !important;
						}
					}

					.radio-wrapper > input:checked + .radio-box {
						&:before {
							background: url('../img/icons/radio-checked.svg') center no-repeat;
							background-size: cover;
						}
					}

					.checkbox-wrapper {
						cursor: pointer;
						display: block;
						margin: 35px 0 0 0;

						.checkbox-box {
							position: relative;
							display: flex;
							align-items: center;

							p {
								font-size: 16px;
								font-weight: 200;
								color: #fff;
							}

							&:before {
								content: '';
								min-width: 10px;
								max-width: 10px;
								height: 10px;
								display: block;
								background: url('../img/icons/checkbox.svg') center no-repeat;
								background-size: cover;
								transition: all 0.3s;
								margin-right: 20px;
							}
						}

						input {
							opacity: 0;
							position: absolute;
							height: auto !important;
							width: auto !important;
						}
					}

					.checkbox-wrapper > input:checked + .checkbox-box {
						&:before {
							background: url('../img/icons/checkbox-checked.svg') center no-repeat;
							background-size: cover;
						}
					}

					.select-2-prefix-wrapper {
						position: relative;
						margin-top: 5px;

						.select2-container {
							position: absolute !important;
							top: 0;
							left: 0;
							height: 100%;
							width: 85px !important;

							.selection {
								height: 100%;

								.select2-selection--single {
									height: 100%;
									background: none;
									border: none;
									display: flex;

									.select2-selection__rendered {
										height: 100%;
										display: flex;
										align-items: center;
										padding-left: 15px;
										padding-right: 0;
										font-size: 16px;
										font-weight: 700;
										color: #fff;
									}

									.select2-selection__arrow {
										height: 100%;
										display: flex;
										align-items: center;
										position: relative;

										b {
											display: none;
										}

										&:after {
											cursor: pointer;
											content: '';
											height: 4px;
											width: 4px;
											background: url('../img/icons/down-chevron-white.svg') center;
											background-size: cover;
											position: absolute;
											left: 50%;
											top: 50%;
											transform: translate(-50%, -50%);
										}
									}
								}
							}
						}

						input {
							margin: 0;
							padding-left: 80px;

							&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
								-webkit-appearance: none;
							}
						}
					}

					.delivery-flexbox {
						margin-top: 30px;
						display: flex;
						align-items: center;
						position: relative;

						> * {
							&:not(:last-child) {
								margin-right: 50px;
							}
						}

						.radio-wrapper {
							.radio-box {
								img {
									margin: 0 20px;
								}

								p {
									font-size: 16px;
									font-weight: 700;
									color: #fff;
									white-space: nowrap;
								}
							}

							&:nth-child(2) {
								.radio-box {
									img {
										height: 32px;
									}
								}
							}
						}

						.info {
							.small-letters(#fff);
							text-transform: none;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
						}
					}

					.express-checkout {
						display: flex;
						align-items: center;
						gap: 30px;

						> * {
							flex-grow: 1 !important;
							width: auto !important;
							border-radius: 2px !important;
						}

						.checkout-service {
							margin-top: 30px;
							flex-grow: 1;
							padding: 15px;
							background: rgba(255, 255, 255, 0.25);
							border: none;
							outline: none;
							border-radius: 2px;
							cursor: pointer;
							transition: all 0.5s;

							img, svg {
								position: relative;
								height: 20px;
							}

							&:hover {
								background: rgba(255, 255, 255, 0.15);
							}
						}
					}

					.payment-wrapper {
						margin-top: 25px;

						.radio-wrapper {
							margin-top: 20px;

							.radio-box {
								p {
									width: 90px;
								}

								img {
									margin-left: 20px;
									height: 30px;
								}

								&:before {
									margin-right: 20px;
								}
							}

							&:last-child {
								.radio-box {
									img {
										height: 20px;
									}
								}
							}
						}
					}

					.terms-wrapper {
						.checkbox-wrapper {
							.checkbox-box {
								p {
									font-size: 13px;
									font-weight: 300;

									a {
										color: inherit;
										text-decoration: underline !important;
									}
								}
							}
						}
					}
				}

				hr {
					margin: 35px 0;
					border-color: rgba(255, 255, 255, 0.25);
				}

				.summary-wrapper {
					margin-top: 50px;

					.wrapper-product {
						margin-top: 40px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.product-image {
							margin-right: 35px;
							min-width: 120px;
							max-width: 120px;

							img {
								max-width: 100%;
								max-height: 90px;
								height: auto;
								width: auto;
							}
						}

						.product-quantity {
							width: 100%;

							.quantity-row {
								display: flex;
								justify-content: space-between;

								p {
									font-size: 18px;
									font-weight: 200;

									&:nth-child(2) {
										font-weight: 700;
									}
								}

								&:not(:first-child) {
									margin-top: 10px;
								}
							}
						}
					}
				}

				.wrapper-chosen {
					margin-top: 35px;
					display: flex;
					align-items: center;

					.chosen-service {
						display: flex;
						align-items: center;

						p {
							font-size: 18px;
							font-weight: 200;
							margin-right: 20px;
						}

						img {
							height: 30px;
						}

						&:not(:last-child) {
							margin-right: 40px;
						}
					}
				}
			}

			&:nth-child(2) {
				position: relative;

				img {
					position: absolute;
					right: 0;
					top: 0;
					height: 650px;
					transform: translate(18.5%, -17.6%);

					&.balance {
						transform: translate(24%, -30%);
					}

					&.st1 {
						height: 750px;
						transform: translate(15%, -30%);
					}

					&.impact {
						height: 750px;
						transform: translate(20%, -40%);
					}
				}
			}
		}
	}

	.tpay-wrapper {
		position: relative;

		h2 {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.tpay-insidebg {
		margin-top: 30px;
		max-width: 100%;
		background: none;

		.tpay-header-wrapper {
			background: none;
			height: auto;
		}

		.tpay-header-logo {
			text-align: right;
			padding-top: 5px;

			img {
				padding: 0;
				width: auto;
				height: 25px;
			}
		}

		.tpay-header-belt {
			display: none;
		}

		.tpay-panel-inside-content {
			margin: 0;
			background: none;
			padding: 0;
		}

		.tpay-groups-wrapper {
			width: 100%;
			margin: 0;
			display: block;
			background: none;

			.tpay-amPmCheckbox {
				margin-top: 35px;
			}
		}

		.tpay-content-wrapper-class {
			background: none;
			overflow: visible;
			width: 100%;
			padding: 0;
		}

		.tpay-col {
			width: 100%;
			margin: 0;
			display: block;
			float: none;

			&:first-child {
				.tpay-row {
					> * {
						width: 100%;
					}
				}
			}
		}

		.tpay-row {
			display: flex;
			justify-content: space-between;

			> * {
				width: 48%;
			}
		}

		.tpay-input-wrapper {
			margin: 0;

			.tpay-input-label {
				margin-top: 30px;
				font-size: 16px;
				font-weight: 200;
				font-family: inherit;
				text-align: left;
				color: #fff;
				padding: 0;
				margin-bottom: 0;
				position: relative;
				top: auto;
				left: auto;
				background: none;
			}

			input {
				margin-top: 5px;
				height: 50px;
				width: 100%;
				border: 1px solid rgba(255, 255, 255, 0.25) !important;
				box-shadow: none !important;
				outline: none !important;
				font-size: 16px;
				color: #fff;
				font-weight: 700;
				border-radius: 0;
				font-family: inherit;
				text-align: left;
				padding: 10px 15px;
				background: none !important;
				transition: all 0.4s;

				&:focus {
					outline: none;
					border: 1px solid rgba(0, 0, 0, 1);
				}
			}

			input::placeholder {
				color: rgba(255, 255, 255, 0.4);
			}
		}

		.tpay-input-credit-card-number {
			margin: 0;
			padding: 0;
			width: 100%;
		}

		.tpay-amPmCheckbox {
			margin: 20px 0 0 0;
			padding: 0;
			cursor: pointer;
			display: block;

			.checkbox-box {
				position: relative;
				display: flex;
				align-items: center;

				label {
					font-size: 13px;
					font-weight: 300;
					color: #fff;
					margin-bottom: 0;
					cursor: pointer;

					a {
						color: inherit;
						text-decoration: underline !important;
					}
				}

				&:before {
					content: '';
					min-width: 10px;
					max-width: 10px;
					height: 10px;
					display: block;
					background: url('../img/icons/checkbox.svg') center no-repeat;
					background-size: cover;
					transition: all 0.3s;
					margin-right: 20px;
				}
			}

			input {
				opacity: 0;
				position: absolute;
				height: auto !important;
				width: auto !important;
			}
		}

		.tpay-amPmCheckbox > input:checked + .checkbox-box {
			&:before {
				background: url('../img/icons/checkbox-checked.svg') center no-repeat;
				background-size: cover;
			}
		}

		#info_msg_not_supported, #info_msg_not_valid {
			text-align: left;
			padding: 10px;
			margin-top: 10px;
			background: #9F8787;
			font-size: 16px;
			font-weight: 200;
		}

		.tpay-buttons-holder {
			text-align: left;
			margin-top: 0;
		}

		#loading_scr {
			margin-top: 50px;
			font-size: 16px;
			font-weight: 200;

			img {
				margin-right: 10px;
			}
		}

		.btn {
			margin-top: 50px;
			padding: 0;
			position: relative;

			.tpay-pay-button {
				padding: 15px 30px 15px 15px;
				border: none;
				background: none;
				outline: none;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.7px;
				color: #fff;
			}

			.chevron {
				position: absolute;
				right: 12px;
				top: 50%;
				transform: rotate(-90deg) translateX(1px) translateY(-50%);
			}
		}
	}
}

section.cover-section-content {
	overflow: hidden;

	.content-inner {
		min-height: 100vh;
		padding: 100px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.logo {
			height: 30px;
			margin: 0 auto;
			display: block;
			position: relative;
			z-index: 111;
		}

		.inner-flexbox {
			display: flex;
			justify-content: space-between;
			margin: 200px 0;

			.flexbox-service {
				width: 50%;
				position: relative;
				z-index: 111;

				h1 {
					color: #fff;
					font-size: 75px;
					font-weight: 200;
					line-height: 80px;
				}

				p {
					margin-top: 30px;
					font-size: 16px;
					font-weight: 200;
					color: #fff;

					a {
						color: inherit;
						font-weight: 700;
					}
				}
			}

			.flexbox-image {
				width: 50%;
				position: relative;

				img {
					position: absolute;
					width: 180%;
					top: 50%;
					right: -20%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.section-footer-extended-wrapper {
	position: relative;
	background-color: #000;

	.footer-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.5;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center 20%;
		}
	}

	section.section-footer {
		background: none;
		margin-top: 90px;

		.footer-flexbox, .footer-credits {
			color: #fff;
		}
	}
}

section.section-footer {
	padding-bottom: 60px;
	position: relative;
	z-index: 111;
	background: #fff;

	&.gray {
		background: #f7f6f6;
	}

	&.black {
		background: #000;

		.footer-flexbox, .footer-credits {
			color: #fff;
		}
	}

	.footer-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #000;

		p, li {
			font-size: 12px;
			font-weight: 300;

			strong {
				font-weight: 700;
			}
		}

		ul {
			display: flex;

			li {
				a {
					color: inherit;
					transition: all 0.4s;

					&:hover {
						opacity: 0.6;
					}
				}

				&:not(:last-child) {
					margin-right: 30px;
				}

				&.breakpoint {
					margin-right: 0;
				}
			}
		}
	}

	.footer-credits {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #000;

		p {
			font-size: 12px;
			font-weight: 300;

			strong {
				font-weight: 700;
			}
		}
	}
}