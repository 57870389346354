
// Large devices (desktops, less than 1200px)
@media (min-width: 1260px) {
	.container {
		max-width: 1260px;
	}
}

@media (min-width: 768px) {
	.dropdown .dropdown-menu{
		display: block; 
		opacity:0;
		-webkit-transition: all 500ms ease-in;
		-moz-transition: all 500ms ease-in;
		-ms-transition: all 500ms ease-in;
		-o-transition: all 500ms ease-in;
		transition: all 500ms ease-in;

		.vrau {
			display:block;
			opacity:1;
		}
	}
}

@media (min-width: 1199.98px) and (max-width: 1259.98px) {
	section.checkout-section-content {
		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					width: 55%;
				}

				&:nth-child(2) {
					width: 45%;
				}
			}
		}
	}

	section.section-footer {
		.footer-credits {
			display: block;

			.credits-info {
				margin-top: 15px;
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	section.section-offer {
		.offer-inner {
			.inner-service {
				&:last-child {
					.device {
						img {
							height: 350px;
						}
					}
				}
			}
		}
	}

	section.product-section-versions {
		.versions-configurator {
			.configurator-gallery {
				.gallery-item {
					img {
						height: 60%;
						transform: translateX(-2%);
					}
				}
			}

			.configurator-body {
				width: 53%;

				.body-options {
					flex-wrap: wrap;
					margin-top: 15px;

					.option-service {
						margin-top: 15px;
						margin-right: 20px;

						& + .option-service {
							margin-left: 0;
						}
					}
				}
			}
		}
	}

	section.checkout-section-content {
		min-height: 750px;

		.content-flexbox {
			align-items: flex-start;

			.flexbox-service {
				&:nth-child(1) {
					width: 65%;
				}

				&:nth-child(2) {
					width: 35%;

					img {
						top: 0;
						left: 0;
						right: auto;
						width: 270%;
						height: auto !important;
						transform: translate(-18.5%, -17.6%);

						&.balance {
							transform: translate(-18%, -20%);
						}

						&.st1 {
							transform: translate(-35%, -33%);
						}

						&.impact {
							transform: translate(-35%, -33%);
						}
					}
				}
			}
		}
	}

	section.section-footer {
		.footer-credits {
			display: block;

			.credits-info {
				margin-top: 20px;
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	nav.section-header {
		padding: 25px 0 !important;

		.header-flexbox {
			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100vh;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;

						li {
							a {
								padding: 10px;

								&:after {
									content: none;
								}
							}

							&.display-desktop {
								display: none;
							}

							&.display-mobile {
								display: block;
							}
						}
					}

					.flexbox-nav-language {
						display: block;
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.nav-button {
				display: flex;
				margin-right: -10px;
			}
		}

		.product-dropdown {
			display: none;
		}

		&.main {
			.header-top {
				display: none;
			}

			.header-flexbox {
				.flexbox-logo-mobile {
					display: block;
				}

				.flexbox-expand {
					display: block;
				}

				.flexbox-language {
					display: none;
				}

				.flexbox-shopping-cart {
					width: auto;

					.button-buy {
						.small-letters(#000);
						margin-left: 25px;
						border: none;
						padding: 0;

						.chevron {
							height: 4px;
							transform: rotate(-90deg);
							margin-left: 10px;
						}
					}
				}
			}
		}

		&.product {
			padding: 0 !important;

			.header-flexbox {
				padding: 25px 0;

				.flexbox-logo {
					a {
						img {
							height: 11px;
						}
					}
				}

				.flexbox-expand {
					display: block;
				}

				.flexbox-shopping-cart {
					width: auto;

					.button-buy {
						color: #fff;
						margin-left: 20px;
						padding: 3px 5px;
					}

					.quantity {
						color: #fff !important;
					}
				}

				.nav-button {
					display: none;
					margin-right: -10px;

					.button-bar {
						background: #fff;
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-background {
			width: 160%;
			margin-left: -27%;
		}
	}

	section.section-offer {
		.offer-inner {
			.inner-service {
				h2 {
					padding: 0 20px;
				}

				.device {
					height: 240px;
				}

				&:first-child {
					.device {
						img {
							margin-top: 15px;
						}
					}
				}

				&:last-child {
					.device {
						img {
							height: 240px;
						}
					}
				}
			}
		}
	}

	section.section-product {
		.product-flexbox {
			flex-direction: column;

			.flexbox-service {
				&:nth-child(1) {
					width: 100%;

					.service-image {
						transform: translateX(13%);

						img {
							width: 100%;
						}
					}
				}

				&:nth-child(2) {
					padding: 50px 0 0 0;
					width: 100%;
				}
			}
		}
	}

	section.section-swiss {
		padding: 70px 0;

		.swiss-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;

				&:nth-child(1) {
					padding: 0;
					padding-right: 0;
				}

				&:nth-child(2) {
					position: relative;

					img {
						position: relative;
						left: auto;
						top: auto;
						height: auto;
						width: 135%;
					}
				}
			}
		}
	}

	section.section-features {
		padding: 70px 0 0 0;

		.tab-content {
			.tab-pane {
				.feature-flexbox {
					flex-direction: column-reverse;

					.flexbox-service {
						width: 100%;

						&:nth-child(1) {
							height: 400px;
						}

						&:nth-child(2) {
							z-index: 111;
							padding: 70px 0 0 0;
							min-height: auto;

							.lights-wrapper {
								grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

								.light-service {
									width: 30px;
									height: 30px;
								}
							}
						}
					}
				}

				&.pane-silence {
					.service-image {
						width: 150%;
						margin-left: -50%;
						right: auto;
						top: auto;
						height: auto;
						transform: none;
					}	
				}

				&.pane-precision {
					.service-image {
						height: 100%;
						width: auto;
						right: auto;
						left: 50%;
						top: 50px;
						transform: translateX(-50%);
					}	
				}

				&.pane-safety {
					.service-image-wrapper {
						height: 120%;
						right: 0;
						top: auto;

						img {
							transform: translateX(5%);
						}
					}	
				}

				&.pane-craftmenship {
					.service-image {
						height: auto;
						width: 100%;
						right: 0;
						top: 50px;
					}	
				}

				&.pane-setup {
					.service-image {
						height: 100%;
						width: auto;
						right: auto;
						left: 50%;
						top: 50px;
						transform: translateX(-50%);
					}	
				}

				&.pane-light {
					.service-image-wrapper {
						right: -30%;
						top: 50px;
					}	
				}

				&.pane-design {
					.service-image {
						height: 100%;
						width: auto;
						right: auto;
						left: 50%;
						top: 0;
						transform: translateX(-50%);
					}	
				}

				&.pane-modularity {
					.service-image {
						text-align: center;
						right: auto;
						left: 50%;
						top: auto;
						bottom: 5%;
						height: 90%;
						transform: translateX(-40%);
					}	
				}

				&.pane-impact-design {
					.service-image {
						right: auto;
						left: 50%;
						transform: translateX(-50%);
					}
				}

				&.pane-battery {
					.battery-icon {
						bottom: 3.7%;
						left: 30%;
						transform: translateX(-100%);
						padding: 12px;

						span {
							font-size: 30px;
							line-height: 30px;
						}

						img {
							margin-top: 12px;
							width: 32px;
						}
					}

					.service-image {
						right: auto;
						left: 50%;
						transform: translateX(-40%);
						top: 10%;
						height: 80%;
					}
				}

				&.pane-impact-setup {
					.service-image {
						right: auto;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}
	}

	section.section-about {
		padding: 70px 0 0 0;

		.section-heading {
			h2 {
				text-align: left;
			}
		}
	}

	section.product-section-welcome-image {
		.welcome-image-inner {
			justify-content: center;
			min-height: auto;
			padding: 100px 0;

			img {
				width: 200%;
			}
		}
	}

	.product-section-welcome-wrapper {
		&.impact {
			background: #fff !important;

			section.product-section-welcome {
				padding-top: 0;

				.welcome-flexbox {
					.flexbox-service {
						&:nth-child(1) {
							padding-top: 0;
						}

						&:nth-child(2) {
							display: none;
						}
					}
				}
			}
		}
	}

	section.product-section-welcome {
		padding: 70px 0 0 0;

		.welcome-flexbox {
			flex-direction: column-reverse;

			.flexbox-service {
				width: 100%;

				&:nth-child(2) {
					img {
						position: relative;
						right: auto;
						left: auto;
						top: auto;
						height: auto;
						width: 104%;
						margin-left: -2%;
					}
				}

				&:nth-child(1) {
					padding: 70px 0;
				}
			}
		}
	}

	section.product-section-specs {
		padding: 0;

		.about-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;

				&:nth-child(1) {
					padding: 70px 0;
					padding-right: 0;
				}

				&:nth-child(2) {
					padding-bottom: 30px;

					img {
						position: relative;
						left: auto;
						top: auto;
						height: auto;
						width: 140%;
					}
				}
			}
		}
	}

	.product-section-versions-wrapper {
		&.impact {
			background: #f7f6f6;
		}
	}

	section.product-section-versions {
		.versions-flexbox {
			margin-top: 30px;
			flex-wrap: wrap;

			.flexbox-service {
				margin-top: 50px;
				width: 50%;
			}
		}

		.versions-configurator {
			margin-top: 50px;
			flex-direction: column;

			.configurator-gallery {
				width: 100%;

				.gallery-item {
					position: relative;

					img {
						height: auto;
						width: 100%;
						transform: translateX(0);
					}

					&:not(:first-child) {
						position: absolute;
					}
				}
			}

			.configurator-body {
				width: 100%;
				margin-top: 70px;
			}
		}
	}

	section.about-us-section-content {
		.content-heading {
			.heading-image {
				position: relative;
				height: auto;
			}

			.heading-inner {
				background: #000;
			}
		}

		.about-slogan {
			.slogan-inner {
				.inner-image {
					display: none;
				}

				.inner-description {
					padding: 70px 0;
				}
			}

			.slogan-image-mobile {
				display: block;
				height: 300px;
				overflow: hidden;

				img {
					width: 150%;
					margin-left: -25%;
				}
			}
		}
	}

	section.winder-section-content {
		padding: 70px 0;
		margin-bottom: 0;
		min-height: auto;

		.content-flexbox {
			flex-direction: column-reverse;

			.flexbox-service {
				width: 100%;

				&:nth-child(1) {
					margin-top: 60px;

					img {
						position: relative;
						right: auto;
						top: auto;
						height: auto;
						width: 120%;
					}
				}

				&:nth-child(2) {
					padding-left: 0;
				}
			}
		}
	}

	section.downloads-section-content {
		padding: 70px 0 50px 0;
		margin-bottom: 0;

		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;

				&:nth-child(1) {
					padding-right: 0;
				}

				&:nth-child(2) {
					margin-top: 70px;

					img {
						position: relative;
						left: auto;
						top: auto;
						height: auto;
						width: 100%;
					}
				}
			}
		}
	}

	section.static-page-section-content {
		padding: 70px 0 60px 0;
		margin-bottom: 0;

		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;

				&:nth-child(1) {
					padding-right: 0;
				}

				&:nth-child(2) {
					margin-top: 60px;
					position: relative;

					img {
						position: relative;
						left: auto;
						top: auto;
						height: auto;
						width: 100%;
					}
				}
			}
		}
	}

	section.blog-section-content {
		padding: 70px 0 50px 0;
		margin-bottom: 0;

		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;

				&:nth-child(1) {
					padding-right: 0;
				}

				&:nth-child(2) {
					margin-top: 60px;

					img {
						position: relative;
						left: auto;
						top: auto;
						height: auto;
						width: 100%;
					}
				}
			}
		}
	}

	section.contact-section-content {
		padding: 70px 0 50px 0;
		margin-bottom: 0;

		.content-heading {
			margin-top: 70px;

			.heading-inner {
				width: 100%;
			}
		}

		.content-form {
			.form-inner {
				width: 100%;
				margin: 30px auto 0 auto;
			}
		}
	}

	section.checkout-section-content {
		padding: 70px 0;
		min-height: auto;

		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				&:nth-child(1) {
					width: 100%;
					padding-right: 0;

					.buttons-wrapper {
						&.margin-top-xl {
							margin-top: 70px;
						}
					}
				}

				&:nth-child(2) {
					width: 100%;
					margin-top: -60px;
					position: relative;

					img {
						position: relative;
						right: auto;
						top: auto;
						height: auto;
						width: 159.5%;
						transform: translateX(-18.5%);

						&.balance {
							width: 125%;
							transform: translate(-16%, -16%);
						}

						&.st1 {
							width: 130%;
							height: auto;
							transform: translate(-20%, -17%);
						}

						&.impact {
							width: 130%;
							height: auto;
							transform: translate(-19%, 0);
						}
					}
				}
			}
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-wrap: wrap;

			ul {
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
		}

		&.black {
			background: none;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.container {
		max-width: 100%;
		padding: 0 50px;
	}

	section.section-welcome {
		.welcome-background {
			display: none;
		}

		.welcome-background-mobile {
			display: block;
			width: 100%;
		}

		.welcome-inner {
			padding: 30px 0;

			.inner-wrapper {
				.wrapper-service {
					h1 {
						font-size: 40px;
					}
				}
			}
		}
	}

	section.section-offer {
		.offer-inner {
			flex-direction: column;

			.inner-service {
				padding: 50px 0;
				width: 100%;

				h2 {
					padding: 0;
				}

				.device {
					margin-top: 40px;
					height: auto;
				}

				&:first-child {
					border-right: none;
					border-bottom: 1px solid #fff;

					.device {
						img {
							margin-top: 0;
							width: 100%;
						}
					}
				}

				&:last-child {
					padding-bottom: 0;

					.device {
						width: 100%;
						padding-top: 84%;
						position: relative;
						overflow: hidden;

						img {
							position: absolute;
							top: 0;
							left: 0;
							height: auto;
							width: 100%;
						}
					}
				}
			}
		}
	}

	section.contact-section-content {
		display: flex;
		flex-direction: column;

		.content-image {
			margin-top: 50px;
			order: 2;
			width: 100%;
			overflow: hidden;

			img {
				width: 200%;
				margin-left: -50%;
			}
		}

		.content-heading {
			margin-top: 0;
			order: 1;
		}

		.content-form {
			order: 3;
		}
	}

	section.section-footer {
		.footer-flexbox {
			ul {
				flex-wrap: wrap;

				.breakpoint {
					width: 100%;
					margin-top: 7px;
					margin-right: 0 !important;
				}
			}
		}
	}
}

@media (max-width: 680px) {
	section.section-features {
		#nav-tabs-wrapper {
			margin-left: -20px;
			transition: all 0.4s;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			scrollbar-width: none;
		}

		#nav-tabs-wrapper::-webkit-scrollbar {
			display: none;
		}

		.nav-tabs {
			justify-content: flex-start;
		}
	}

	section.checkout-section-content {
		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					.summary-wrapper {
						.wrapper-product {
							flex-direction: column;
							align-items:flex-start;

							.product-quantity {
								margin-top: 30px;

								.quantity-row {
									flex-direction: column;
									text-align: left !important;
								}
							}
						}

						.wrapper-total {
							justify-content: flex-start;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 660px) {
	section.section-features {
		#nav-tabs-wrapper {
			&.position-1 {
				margin-left: -20px;
			}

			&.position-2 {
				margin-left: -10%;
			}

			&.position-3 {
				margin-left: -20%;
			}

			&.position-4 {
				margin-left: -30%;
			}

			&.position-5 {
				margin-left: -40%;
			}

			&.position-6 {
				margin-left: -50%;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.container {
		padding: 0 30px;
	}

	section.section-welcome {
		.welcome-background-mobile {
			width: 120%;
			margin-left: -10%;
		}

		.welcome-inner {
			padding: 30px 0;

			.inner-wrapper {
				.wrapper-service {
					h1 {
						font-size: 35px;
					}
				}
			}
		}
	}

	section.section-offer {
		.offer-inner {
			.inner-service {
				h2 {
					font-size: 35px;
				}

				p {
					font-size: 14px;
				}
			}
		}
	}

	section.section-product {
		.product-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					.service-image {
						position: relative;
						transform: translateX(0%);

						img {
							width: 125%;
						}
					}
				}

				&:nth-child(2) {
					padding: 30px 0 0 0;

					h2 {
						font-size: 35px;
					}

					h3 {
						font-size: 23px;
					}

					p {
						font-size: 14px;
					}
				}
			}
		}
	}

	section.section-swiss {
		.swiss-made {
			display: none;
		}

		.swiss-flexbox {
			flex-direction: column-reverse;

			.flexbox-service {
				&:nth-child(1) {
					margin-top: 40px;

					h2 {
						font-size: 35px;
					}

					h3 {
						font-size: 23px;
					}

					p {
						font-size: 14px;
					}
				}
			}
		}
	}

	section.section-features {
		.section-heading {
			h2 {
				font-size: 35px;
			}
		}

		.tab-content {
			.tab-pane {
				.feature-flexbox {
					.flexbox-service {
						&:nth-child(2) {
							h3 {
								font-size: 23px;
							}
						}
					}
				}

				&.pane-silence {
					.service-image {
						width: auto;
						height: 100%;
						margin-left: 0;
						right: 0;
					}	
				}

				&.pane-battery {
					.battery-icon {
						left: 50%;
						transform: translateX(-50%) translateY(100%);
						bottom: 400px;
						flex-direction: row-reverse;
						text-align: left;

						span {
							margin-top: 5px;
							font-size: 27px;
							line-height: 27px;
						}

						img {
							margin-top: 0;
							margin-right: 15px;
							width: 32px;
						}
					}

					.service-image {
						right: auto;
						left: 50%;
						transform: translateX(-50%);
						top: 25%;
						height: 70%;
					}
				}

				&.pane-quality {
					.service-image {
						height: 142%;
						transform: translateX(25%);
						top: 10%;
					}
				}
			}
		}
	}

	section.section-about {
		.section-heading {
			h2 {
				font-size: 35px;
			}
		}

		.about-item {
			margin-top: 40px;

			h3 {
				font-size: 23px;
			}

			p {
				font-size: 14px;
			}
		}
	}

	section.product-section-canvas {
		display: none;
	}

	section.product-section-welcome {
		.welcome-flexbox {
			.flexbox-service {
				&:nth-child(2) {
					img {
						width: 150%;
						margin-left: -50%;
						-webkit-animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
						animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
					}

					@-webkit-keyframes slide-right-product {
						0% {margin-left: -50%;}
						50% {margin-left: 0;}
						100% {margin-left: -50%;}
					}

					@keyframes slide-right-product {
						0% {margin-left: -50%;}
						50% {margin-left: 0;}
						100% {margin-left: -50%;}
					}
				}

				&:nth-child(1) {
					padding: 50px 0 70px 0;

					.genesis-logo {
						height: 15px;
					}

					h1 {
						margin-top: 15px;
						font-size: 35px;
						line-height: 55px;
					}

					h2 {
						font-size: 23px;
					}

					p {
						font-size: 14px;
					}
				}
			}
		}
	}

	section.product-section-hero-image {
		.image-inner {
			h2 {
				font-size: 35px;
			}
		}
	}

	section.product-section-gallery {
		padding: 60px 0;

		.container {
			padding: 0;
		}

		.owl-carousel {
			img {
				width: 150%;
			}

			.item {
				background-position: center;
				// -webkit-animation: slide-right-background 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
				// animation: slide-right-background 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
			}

			@-webkit-keyframes slide-right-background {
				0% {background-position: left center;}
				50% {background-position: right center;}
				100% {background-position: left center;}
			}

			@keyframes slide-right-background {
				0% {background-position: left center;}
				50% {background-position: right center;}
				100% {background-position: left center;}
			}
		}
	}

	section.product-section-specs {
		&.padding-100 {
			padding: 50px 0;
		}

		.section-heading {
			margin-bottom: 50px;

			h2 {
				font-size: 35px;
			}
		}

		.about-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					.service-heading {
						.value {
							font-size: 35px;
						}
					}

					ul {
						li {
							p {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}

	section.product-section-versions {
		padding: 60px 0;

		h2 {
			font-size: 35px;
		}

		.versions-options {
			margin-top: 35px;
			display: flex;
		}

		.versions-flexbox {
			margin-top: 10px;
			position: relative;

			.flexbox-service {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				opacity: 0;
				visibility: hidden;
				transition: all 0.4s;

				.device {
					order: 2;
					margin: 13px auto 0 auto;
					transform: none;
				}

				.logo {
					margin: 0 auto;
					order: 1;
				}

				.price {
					margin-top: 10px;
					order: 3;

					p {
						font-size: 14px;
					}

					.value {
						font-size: 35px;
					}

					.btn {
						margin: 0;
						display: block;
						transform: translateY(3px);
					}
				}

				ul {
					order: 4;
				}

				.btn {
					display: none;
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}

				&:not(:first-child) {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
				}
			}
		}

		.versions-configurator {
			.configurator-body {
				margin-top: 40px;

				h3 {
					font-size: 23px;

					&:not(:first-child) {
						margin-top: 40px;
					}
				}

				.body-options {
					margin-top: 10px;

					.option-service {
						margin-top: 10px;

						.service-image {
							height: 45px;
							width: 45px;
						}

						.service-name {
							font-size: 16px;
							margin-left: 10px;
							width: 90px;
						}
					}
				}

				.body-price {
					margin-top: 40px;
					flex-wrap: wrap;

					.price-inner {
						width: 100%;
						margin-bottom: 20px;
					}

					.price-input {
						margin-left: 0;
					}
				}
			}
		}
	}

	section.about-us-section-content {
		overflow: hidden;

		.content-heading {
			.heading-inner {
				padding: 60px 0;
				background: #000;

				p {
					font-size: 23px;
				}
			}
		}

		.about-slogan {
			.slogan-inner {
				.inner-description {
					padding: 60px 0;
					
					h3 {
						font-size: 23px;
					}

					p {
						margin-top: 30px;
						font-size: 14px;
					}
				}
			}


			.slogan-image-mobile {
				height: 200px;

				img {
					width: 180%;
					margin-left: -40%;
				}
			}
		}
	}

	section.winder-section-content {
		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					img {
						width: 150%;
						margin-left: -50%;
						-webkit-animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
						animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
					}
				}

				&:nth-child(2) {
					h1 {
						font-size: 35px;
					}

					h2 {
						font-size: 23px;
					}

					.description {
						p {
							font-size: 14px;
						}
					}

					.content-search-engine {
						p {
							font-size: 14px;
						}

						input {
							font-size: 14px;
						}
					}

					ul {
						li {
							h3 {
								font-size: 35px;
							}

							.name {
								font-size: 14px;
							}

							.feature {
								p {
									font-size: 12px;

								}
							}

							&:not(:last-child) {
								margin-bottom: 10px;
								padding-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}

	section.downloads-section-content {
		padding-bottom: 60px;

		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					h1 {
						font-size: 35px;
					}

					.service-image-mobile {
						margin-top: 50px;
						display: block;

						img {
							width: 150%;
							margin-left: -50%;
							-webkit-animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
							animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
						}
					}

					.service-download {
						.download-box {
							flex-direction: column;
							align-items: flex-start;

							.box-image {
								min-width: 30%;
								max-width: 30%;
								margin-right: 0;
							}

							.box-description {
								margin-top: 30px;

								h2 {
									font-size: 23px;
								}

								p {
									font-size: 14px;
								}
							}
						}
					}
				}

				&:nth-child(2) {
					display: none;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					h1 {
						font-size: 35px;
					}

					.service-inner {
						h2 {
							font-size: 23px;
						}

						p, li {
							font-size: 14px;
						}
					}
				}

				&:nth-child(2) {
					img {
						width: 150%;
						margin-left: -50%;
						-webkit-animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
						animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
					}
				}
			}
		}
	}

	section.blog-section-content {
		.content-flexbox {
			.flexbox-service {
				.newest-inner {
					.inner-description {
						h2 {
							font-size: 23px;
						}

						p {
							font-size: 14px;
						}
					}
				}

				.news-wrapper {
					.news-service {
						flex-direction: column;

						.service-image {
							width: 100%;
						}

						.service-description {
							margin-top: 30px;
							width: 100%;

							h2 {
								font-size: 23px;
								margin: 7px 0;
							}

							p {
								font-size: 14px;
							}
						}
					} 
				}

				.post-inner {
					.inner-heading {
						h1 {
							font-size: 23px;
						}

						p {
							font-size: 14px;
						}
					}

					.inner-description {

						h2 {
							font-size: 23px;

							a {
							}
						}

						p, li {
							font-size: 14px;
						}
					}
				}

				&:nth-child(1) {
					h1, .service-heading {
						font-size: 35px;
					}

					h2 {
						font-size: 23px;
					}
				}

				&:nth-child(2) {
					img {
						width: 150%;
						margin-left: -50%;
						-webkit-animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
						animation: slide-right-product 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;

					}
				}
			}
		}
	}

	section.contact-section-content {
		padding: 40px 0;

		.content-heading {
			.heading-inner {
				h1 {
					font-size: 35px;
					font-weight: 200;
					text-align: center;
				}

				h2 {
					margin-top: 10px;
					font-size: 23px;
				}
			}
		}

		.content-form {
			.form-inner {
				margin: 30px auto 0 auto;
			}
		}
	}

	section.checkout-section-content {
		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					h1 {
						font-size: 35px;
					}

					h2 {
						font-size: 23px;
					}

					.quantity-wrapper-outer {
						.quantity-heading {
							h2 {
								font-size: 20px;
							}
						}

						.quantity-version {
							h3 {
								span {
									display: none;
								}
							}
						}
					}

					.quantity-wrapper {
						margin-top: 60px;
					}

					.service-total {
						.total-delivery {
							p {
								font-size: 18px;
							}
						}

						.total-price {
							p {
								font-size: 18px;
							}
						}
					}

					.buttons-wrapper {
						justify-content: space-between;

						> * {
							&:not(:last-child) {
								margin-right: 0;
							}
						}
					}

					.content-form {
						margin-top: 50px;

						&.express-checkout-wrapper {
							margin-top: -27px;
						}

						label {
							font-size: 14px;
						}

						input, textarea {
							font-size: 14px;
						}

						.select-wrapper {
							select {
								font-size: 14px;
							}
						}

						.delivery-flexbox {
							flex-direction: column;
							align-items: flex-start;

							> * {
								&:not(:last-child) {
									margin-right: 0;
									margin-bottom: 30px;
								}
							}

							.info {
								.small-letters(#fff);
								position: relative;
								top: auto;
								right: auto;
								transform: none;
								margin-top: 0;
							}
						}
					}

					.wrapper-chosen {
						flex-direction: column;
						align-items: flex-start;

						.chosen-service {
							p {
								font-size: 16px;
								width: 80px;
							}

							&:not(:last-child) {
								margin-right: 0;
								margin-bottom: 20px;
							}
						}
					}
				}

				&:nth-child(2) {
					img {
						height: auto;
						width: 260%;
						margin-left: -110%;
						transform: none;
						-webkit-animation: slide-right-checkout 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
						animation: slide-right-checkout 25s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;

						&.balance {
							width: 260%;
							margin-left: -110%;
							transform: translateY(-10%);
						}

						&.st1 {
							width: 260%;
							margin-left: -110%;
							transform: translateY(-17%);
						}

						&.impact {
							width: 260%;
							margin-left: -110%;
							transform: translateY(-17%);
						}
					}

					@-webkit-keyframes slide-right-checkout {
						0% {margin-left: -110%;}
						50% {margin-left: -50%;}
						100% {margin-left: -110%;}
					}

					@keyframes slide-right-checkout {
						0% {margin-left: -110%;}
						50% {margin-left: -50%;}
						100% {margin-left: -110%;}
					}
				}
			}
		}
	}

	section.cover-section-content {
		.content-inner {
			padding: 50px 0;
			justify-content: space-between;

			.inner-flexbox {
				margin: 0;
				flex-direction: column-reverse;

				.flexbox-image {
					width: 100%;
					min-height: 60vh;

					img {
						width: auto;
						height: 120%;
						top: -10%;
						right: auto;
						left: 50%;
						transform: translate(-50%, 0);
					}
				}

				.flexbox-service {
					width: 100%;
					text-align: center;

					h1 {
						font-size: 55px;
						line-height: 55px;
					}

					p {
						margin-top: 30px;
						font-size: 16px;
						font-weight: 200;
						color: #fff;

						a {
							color: inherit;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 447px) {
	section.section-features {
		#nav-tabs-wrapper {
			&.position-2 {
				margin-left: -30%;
			}

			&.position-3 {
				margin-left: -45%;
			}

			&.position-4 {
				margin-left: -60%;
			}

			&.position-5 {
				margin-left: -80%;
			}

			&.position-6 {
				margin-left: -100%;
			}
		}

		.tab-content {
			.tab-pane {
				&.pane-silence {
					.service-image {
						top: 50px;
						right: -15%;
					}	
				}

				&.pane-precision {
					.service-image {
						left: 0;
						transform: translateX(-3%);
					}	
				}

				&.pane-safety {
					.service-image-wrapper {
						height: 110%;

						img {
							transform: translateX(7%);
							display: block;
						}
					}	
				}

				&.pane-craftmenship {
					.service-image {
						height: 100%;
						width: auto;
						right: auto;
						left: 50%;
						transform: translateX(-50%);
					}	
				}

				&.pane-setup {
					.service-image {
						left: auto;
						right: 0;
						transform: translateX(4%);
					}	
				}

				&.pane-light {
					.service-image-wrapper {
						top: 0;
					}
				}

				&.pane-mechanism {
					.service-image {
						height: 100%;
						width: auto;
						right: auto;
						left: 50%;
						transform: translateX(-50%);
					}	
				}
			}
		}
	}

	section.product-section-specs {
		.about-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					ul {
						li {
							flex-direction: column;
							align-items: flex-start;
						}
					}
				}
			}
		}
	}

	section.about-us-section-content {
		.about-slogan {
			.slogan-inner {
				.inner-description {
					padding-bottom: 290px;
				}

				.inner-image {
					right: -60%;
					height: 95%;
				}
			}
		}
	}

	section.checkout-section-content {
		.content-flexbox {
			.flexbox-service {
				&:nth-child(1) {
					.content-form {
						&.express-checkout-wrapper {
							margin-top: 30px;
						}

						.express-checkout {
							gap: 15px;

							.checkout-service {

							}
						}
					}

					.service-total {
						.total-inner {
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: flex-end;
							justify-content: space-between;
						}
					}
				}
			}
		}
	}

	section.section-footer {
		padding-bottom: 50px;

		.footer-flexbox {
			p, li {
				font-size: 11px;
				letter-spacing: -0.2px;
			}

			ul {
				li {
					&:not(:last-child) {
						margin-right: 15px;
					}
				}
			}
		}

		.footer-credits {
			p, .credits-info {
				font-size: 11px;
				letter-spacing: -0.2px;
			}
		}
	}
}

@media (max-width: 360px) {
	section.section-features {
		#nav-tabs-wrapper {
			&.position-2 {
				margin-left: -15%;
			}

			&.position-3 {
				margin-left: -30%;
			}

			&.position-4 {
				margin-left: -70%;
			}

			&.position-5 {
				margin-left: -100%;
			}

			&.position-6 {
				margin-left: -130%;
			}
		}
	}
}